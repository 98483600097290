<template>
    <div class="contract-alert-box">
        <div class="contract-alert-content">
            <div v-show="changeBox" class="contract-page-one">
                <div class="content-left">
                    <div class="content-left-top">
                        <div class="top-box-one">
                            <h1>合同信息</h1>
                            <div>
                                <p>合同编号:【QZ】XS120200305005</p>
                                <p>客户名称:山东乐安集团有限公司</p>
                            </div>
                            <div>
                                <p class="blue-color">
                                    工程名称:乐安居西区四期 <span class="iconfont">&#xe69e;</span>
                                </p>
                                <p>工程类型:房建</p>
                            </div>
                            <div>
                                <p class="blue-color">
                                    单体数量:100个 <span class="iconfont">&#xe69e;</span>
                                </p>
                            </div>
                            <div>
                                <p>客户联系:梦远方</p>
                                <p>电话:17600060006</p>
                            </div>
                            <div>
                                <p>业务员系:梦远方</p>
                                <p>电话:17600060006</p>
                            </div>
                            <div class="bottom-btn">
                                <button>
                                    合同审批详情 <span class="iconfont">&#xe69e;</span>
                                </button>
                                <button>
                                    合同变更详情 <span class="iconfont">&#xe69e;</span>
                                </button>
                            </div>
                        </div>
                        <div class="top-box-tow">
                            <div class="box-right-top">
                                <div class="box-right-top-mes">
                                    <h1>工程信息</h1>
                                    <div class="message-box-tow">
                                        <div>
                                            <p><span class="iconfont">&#xe6a0;</span> 供货站点</p>
                                            <p>青州前建站</p>
                                        </div>
                                        <div>
                                            <p><span class="iconfont">&#xe69f;</span> 运距(km)</p>
                                            <p>45</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-right-top-map">
                                    <el-amap
                                        vid="amapDemo"
                                        :zoom="zoom"
                                        :center="center"
                                        class="amap-demo"
                                        :zoom-enable="false"
                                        :scroll-wheel="false"
                                        :double-click-zoom="false"
                                    >
                                        <el-amap-marker
                                            v-for="(marker, idx) in markers"
                                            :key="idx"
                                            :offset="marker.offset"
                                            :position="marker.position"
                                        ></el-amap-marker>
                                        <el-amap-text
                                            v-for="(t, index) in texts"
                                            :key="index"
                                            :text="t.text"
                                            :offset="t.offset"
                                            :position="t.position"
                                            :events="t.events"
                                        ></el-amap-text>
                                    </el-amap>
                                </div>
                            </div>
                            <div class="box-right-bottom">
                                <div class="image-progress">
                                    <h1>形象进度</h1>
                                    <h5 class="image-progress-look-more">
                                        更多 <span class="iconfont">&#xe69e;</span>
                                    </h5>
                                </div>
                                <div>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-left-bottom">
                        <div class="time-line-box">
                            <div class="time-line-title">
                                <h1>时间轴</h1>
                                <span>全部</span>
                                <span>本月</span>
                                <span>本周</span>
                            </div>
                            <div class="time-axis" style="height:.8rem">
                                <div v-for="(item, index) in timeLineNumber" :key="index" :class="(index+1)%7 === 0 ? 'everyWeek': ''">
                                    <b class="iconfont">&#xe6a0;</b>
                                    <p></p>
                                    <span>{{ index + 1 }}</span>
                                </div>
                            </div>
                            <div class="time-axis-bottom-blcok">
                                <div class="orange-show-time">
                                    <p>订单数(个):</p><b>6 </b> <span class="iconfont">&#xe69e;</span>
                                </div>
                                <div>
                                    <p>签收方量(万方):</p><b>612</b>
                                </div>
                                <div>
                                    <p>超限发货次数(次):</p><b>236</b>
                                </div>
                                <div class="orange-show-time">
                                    <p>发货车次(次):</p><b>6 </b> <span class="iconfont">&#xe69e;</span>
                                </div>
                                <div>
                                    <p>签收率(%):</p><b>66.88</b>
                                </div>
                                <div>
                                    <p>当前超限发货量(方):</p><b>33</b>
                                </div>
                            </div>
                        </div>
                        <div class="echarts-two">
                            <div class="take-time">
                                <div style="border:none;width:100%">
                                    <h1>要料时间</h1>
                                </div>
                                <div style="width:100%;height:240px" id="takeTime"></div>
                            </div>
                            <div class="take-time">
                                <div style="border:none;width:100%;height:.2rem">
                                    <h1>产品型号需求</h1>
                                </div>
                                <div style="width:100%;height:240px;flex:6" id="productModel"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-right">
                    <div class="number-div">
                        <div class="one-page-right-top">
                            <div class="number-title">
                                <img src="../../assets/images/contractManagement/liang.png">
                                <p>已供方量(方)：<span>44892</span></p>
                            </div>
                            <div class="position-div" :style="{color: selectColorOne}" v-if="changeBox">
                                <p>{{ text }}</p>
                                <p v-if="subText">
                                    {{ subText }}%
                                </p>
                            </div>
                            <div id="alreadySupplied" v-if="changeBox" style="width:100%;height:100px"></div>
                            <div class="number-content">
                                <div>
                                    <h3>已供方量(方)</h3>
                                    <p style="color:#2096F3">
                                        #2096F3
                                    </p>
                                </div>
                                <div>
                                    <h3>预计方量(方)</h3>
                                    <p style="color:#FA8436">
                                        #FA8436
                                    </p>
                                </div>
                                <div>
                                    <h3>预计剩余(方)</h3>
                                    <p style="color:#4DAF50">
                                        #4DAF50
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="price-div">
                        <div style="padding: .2rem">
                            <div class="price-title">
                                <img src="../../assets/images/contractManagement/jia.png">
                                <p>C30当前价(元/方)：<span>439</span></p>
                            </div>
                            <div class="prize-content">
                                <div>
                                    <p><span class="iconfont">&#xe646;</span> 较企业均价</p>
                                    <p style="background:rgba(235,101,111,0.06);color:#FC636E">
                                        9%↑
                                    </p>
                                </div>
                                <div class="prize-green-div">
                                    <p><span class="iconfont">&#xe646;</span> 签订价格(元/方)</p>
                                    <p>9%↑</p>
                                </div>
                                <div class="prize-blue-div">
                                    <p><span class="iconfont">&#xe646;</span> 调价次数(次)</p>
                                    <p>9%↑</p>
                                </div>
                                <div class="prize-button-div">
                                    <button>产品价格明细 <span class="iconfont">&#xe69e;</span></button>
                                    <button>调价明细 <span class="iconfont">&#xe69e;</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="receivables-div">
                        <div style="padding: .2rem">
                            <div class="receivables-title">
                                <img src="../../assets/images/contractManagement/kuan.png">
                                <p>应收款(万元)：<span>439</span></p>
                            </div>
                            <div class="receivables-content">
                                <div>
                                    <p>实收款</p>
                                    <p>92.5</p>
                                </div>
                                <div>
                                    <p>回款率</p>
                                    <p>92.5</p>
                                </div>
                                <div class="receivables-green-div">
                                    <p>较企业回款率</p>
                                    <p>92.5</p>
                                </div>
                            </div>
                            <div class="receivables-center">
                                <p>已开票金额(万元)</p>
                                <p>92.5</p>
                            </div>
                            <div class="detailed-button">
                                <button>
                                    对账明细
                                    <span class="iconfont">&#xe69e;</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!changeBox" class="contract-page-two">
                <div class="contract-left">
                    <div class="chart-one">
                        <h1>需求趋势</h1>
                        <div id="demandTrend"></div>
                    </div>
                    <div class="chart-two">
                        <h1>施工进度</h1>
                        <div id="constructionProgress"></div>
                    </div>
                    <div class="chart-three">
                        <h1>合同回款率</h1>
                        <div id="contractRefund"></div>
                    </div>
                    <div class="chart-four">
                        <h1>授信额度</h1>
                        <div class="chart-four-content">
                            <div class="blue-water-level water-level">
                                <dv-water-level-pond :config="config" style="width:1.22rem;height:1.22rem"></dv-water-level-pond>
                                <div class="water-level-block water-block-blue">
                                    <h3>授信金额(万元)</h3>
                                    <p>10</p>
                                </div>
                                <div class="water-level-block water-block-orange">
                                    <h3>已用金额(万元)</h3>
                                    <p>10</p>
                                </div>
                            </div>
                            <div class="yellow-water-level water-level">
                                <dv-water-level-pond :config="configs" style="width:1.22rem;height:1.22rem"></dv-water-level-pond>
                                <div class="water-level-block  water-block-blue">
                                    <h3>授信方量(方)</h3>
                                    <p>200</p>
                                </div>
                                <div class="water-level-block water-block-orange">
                                    <h3>已用方量(方)</h3>
                                    <p>200</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contract-right" v-if="!changeBox">
                    <div class="contract-right-top">
                        <div class="number-title">
                            <img src="../../assets/images/contractManagement/ben.png" alt="">
                            <p>直接材料成本(元/方)：<span>44892</span></p>
                        </div>
                        <div class="contract-right-top-center">
                            <p>较企业直接成本(元/方)：<span>7↑</span></p>
                            <button>消耗明细<span class="iconfont">&#xe69e;</span></button>
                        </div>
                        <div class="position-div" :style="{color: selectColorTwo}" v-if="!changeBox">
                            <p>{{ textTwo }}</p>
                            <p v-if="subTextTwo">
                                {{ subTextTwo }}%
                            </p>
                        </div>
                        <div v-if="!changeBox" style="width:100%;height:100px" id="directMaterialCost"></div>
                    </div>
                    <div class="contract-right-center">
                        <div class="number-title">
                            <img src="../../assets/images/contractManagement/li.png" alt="">
                            <p>合同毛利率(%)：<span>99.99</span></p>
                        </div>
                        <div class="contract-center-bottom">
                            <div>
                                <p><b></b> 较企业</p>
                                <span>6.19↑</span>
                            </div>
                            <span class="shuxian"></span>
                            <div class="blue-color-contract">
                                <p><b></b> 企业毛利率</p>
                                <span>26.00%</span>
                            </div>
                            <div class="bottom-width-bfb blue-color-contract">
                                <p><b></b> 合同单方成本差(元/方)</p>
                                <span>26.00%</span>
                            </div>
                        </div>
                    </div>
                    <div class="contract-right-bottom">
                        <ul>
                            <li>
                                <p><span class="iconfont">&#xe6fe;</span>合同预警</p>
                                <button>更多<span class="iconfont">&#xe69e;</span></button>
                            </li>
                            <li>
                                <p><span class="iconfont">&#xe6fe;</span>此处为预警内容</p>
                                <span>2020-04-08 11:49</span>
                            </li>
                            <li>
                                <p><span class="iconfont">&#xe6fe;</span>此处为预警内容</p>
                                <span>2020-04-08 11:49</span>
                            </li>
                            <li>
                                <p><span class="iconfont">&#xe6fe;</span>此处为预警内容</p>
                                <span>2020-04-08 11:49</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-show="changeBox" class="checkout-div-page" @click="changeBoxPage">
                <img src="../../assets/images/contractManagement/down.png" alt="">
                <img src="../../assets/images/contractManagement/down.png" alt="">
            </div>
            <div v-show="!changeBox" class="checkout-div-page" @click="changeBoxPage">
                <img src="../../assets/images/contractManagement/up.png" alt="">
                <img src="../../assets/images/contractManagement/up.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
// import { weekInMonthCount } from '../../plugins/weekInMonthCount';
import VueAMap from 'vue-amap';
VueAMap.initAMapApiLoader({
    key: 'b4007ecc143884e79eab2c515ede82cf',
    // eslint-disable-next-line max-len
    plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder', 'AMap.AMapManager', 'AMap.Marker'],
    v: '1.4.4',
});
const amapManager = new VueAMap.AMapManager();
export default {
    data() {
        return {
            // 地图相关
            amapManager,
            zoom: 13,
            center: [121.5273285, 31.21515044],
            markers: [{
                position: [121.5273285, 31.21515044],
                offset: [0, -5],
            }],
            texts: [{
                position: [121.5273285, 31.21515044],
                text: '上海市浦东新区',
                offset: [4, -20],
            }],
            componentMarker: {
                position: [121.5273285, 31.21515044],
            },
            // 内容
            changeBox: true,
            timeLineNumber: 0,
            config: {
                data: [80],
                shape: 'round',
                waveHeight: 5,
                waveNum: 5,
                colors: ['#0DA9EC', '#0DA9EC'],
            },
            configs: {
                data: [55],
                shape: 'round',
                waveHeight: 5,
                waveNum: 5,
                colors: ['#FFB205', '#FFB205'],
            },
            text: '',
            subText: '',
            selectColorOne: '',
            textTwo: '',
            subTextTwo: '',
            selectColorTwo: '',
            ydata: [
                {
                    name: '测量工',
                    value: 3,
                }, {
                    name: '电焊工',
                    value: 2,
                }, {
                    name: '钢筋工',
                    value: 26,
                }, {
                    name: '沥青工',
                    value: 24,
                }, {
                    name: '安装工',
                    value: 12,
                }, {
                    name: '起重工',
                    value: 11,
                }, {
                    name: '养护工',
                    value: 3,
                }, {
                    name: '其它',
                    value: 2,
                },
            ],
            ydataTwo: [
                {
                    name: '测量',
                    value: 3,
                }, {
                    name: '电焊',
                    value: 2,
                }, {
                    name: '钢筋',
                    value: 26,
                }, {
                    name: '沥青',
                    value: 24,
                }, {
                    name: '安装',
                    value: 12,
                }, {
                    name: '起重',
                    value: 11,
                }, {
                    name: '养护',
                    value: 3,
                }, {
                    name: '其它',
                    value: 2,
                },
            ],
        };
    },
    mounted() {
        const year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;
        this.timeLineNumber = this.mGetDate(year, month);
        this.init();
    },
    methods: {
        // 当前月份天数
        mGetDate(year, month) {
            const d = new Date(year, month, 0);
            return d.getDate();
        },
        init() {
            if (this.changeBox) {
                setTimeout(() => {
                    this.takeTimeCharts(); // 要料时间
                    this.productDemandCharts(); // 产品型号需求
                    this.alreadySuppliedCharts(); // 已供应方量
                });
            } else {
                setTimeout(() => {
                    this.demandTrendCharts(); // 需求趋势
                    this.contractRefundCharts(); // 合同回款率
                    this.constructionProgressCharts(); // 施工进度
                    this.directMaterialCostCharts(); // 直接材料成本
                }, 100);
            }
        },
        // 要料时间
        takeTimeCharts() {
            const myChart = this.$echarts.init(document.getElementById('takeTime'));
            const hours = ['12a', '1a', '2a', '3a', '4a', '5a', '6a',
                '7a', '8a', '9a', '10a', '11a',
                '12p', '1p', '2p', '3p', '4p', '5p',
                '6p', '7p', '8p', '9p', '10p', '11p'];
            const days = ['Saturday', 'Friday', 'Thursday',
                'Wednesday', 'Tuesday', 'Monday', 'Sunday'];
            // eslint-disable-next-line max-len
            let data = [[0, 0, 5], [0, 1, 1], [0, 2, 0], [0, 3, 0], [0, 4, 0], [0, 5, 0], [0, 6, 0], [0, 7, 0], [0, 8, 0], [0, 9, 0], [0, 10, 0], [0, 11, 2], [0, 12, 4], [0, 13, 1], [0, 14, 1], [0, 15, 3], [0, 16, 4], [0, 17, 6], [0, 18, 4], [0, 19, 4], [0, 20, 3], [0, 21, 3], [0, 22, 2], [0, 23, 5], [1, 0, 7], [1, 1, 0], [1, 2, 0], [1, 3, 0], [1, 4, 0], [1, 5, 0], [1, 6, 0], [1, 7, 0], [1, 8, 0], [1, 9, 0], [1, 10, 5], [1, 11, 2], [1, 12, 2], [1, 13, 6], [1, 14, 9], [1, 15, 11], [1, 16, 6], [1, 17, 7], [1, 18, 8], [1, 19, 12], [1, 20, 5], [1, 21, 5], [1, 22, 7], [1, 23, 2], [2, 0, 1], [2, 1, 1], [2, 2, 0], [2, 3, 0], [2, 4, 0], [2, 5, 0], [2, 6, 0], [2, 7, 0], [2, 8, 0], [2, 9, 0], [2, 10, 3], [2, 11, 2], [2, 12, 1], [2, 13, 9], [2, 14, 8], [2, 15, 10], [2, 16, 6], [2, 17, 5], [2, 18, 5], [2, 19, 5], [2, 20, 7], [2, 21, 4], [2, 22, 2], [2, 23, 4], [3, 0, 7], [3, 1, 3], [3, 2, 0], [3, 3, 0], [3, 4, 0], [3, 5, 0], [3, 6, 0], [3, 7, 0], [3, 8, 1], [3, 9, 0], [3, 10, 5], [3, 11, 4], [3, 12, 7], [3, 13, 14], [3, 14, 13], [3, 15, 12], [3, 16, 9], [3, 17, 5], [3, 18, 5], [3, 19, 10], [3, 20, 6], [3, 21, 4], [3, 22, 4], [3, 23, 1], [4, 0, 1], [4, 1, 3], [4, 2, 0], [4, 3, 0], [4, 4, 0], [4, 5, 1], [4, 6, 0], [4, 7, 0], [4, 8, 0], [4, 9, 2], [4, 10, 4], [4, 11, 4], [4, 12, 2], [4, 13, 4], [4, 14, 4], [4, 15, 14], [4, 16, 12], [4, 17, 1], [4, 18, 8], [4, 19, 5], [4, 20, 3], [4, 21, 7], [4, 22, 3], [4, 23, 0], [5, 0, 2], [5, 1, 1], [5, 2, 0], [5, 3, 3], [5, 4, 0], [5, 5, 0], [5, 6, 0], [5, 7, 0], [5, 8, 2], [5, 9, 0], [5, 10, 4], [5, 11, 1], [5, 12, 5], [5, 13, 10], [5, 14, 5], [5, 15, 7], [5, 16, 11], [5, 17, 6], [5, 18, 0], [5, 19, 5], [5, 20, 3], [5, 21, 4], [5, 22, 2], [5, 23, 0], [6, 0, 1], [6, 1, 0], [6, 2, 0], [6, 3, 0], [6, 4, 0], [6, 5, 0], [6, 6, 0], [6, 7, 0], [6, 8, 0], [6, 9, 0], [6, 10, 1], [6, 11, 0], [6, 12, 2], [6, 13, 1], [6, 14, 3], [6, 15, 4], [6, 16, 0], [6, 17, 0], [6, 18, 0], [6, 19, 0], [6, 20, 1], [6, 21, 2], [6, 22, 2], [6, 23, 6]];
            data = data.map(function (item) {
                return [item[1], item[0], item[2]];
            });

            const option = {
                tooltip: {
                    position: 'top',
                    formatter: function (params) {
                        return params.value[2] + ' commits in ' + hours[params.value[0]] + ' of ' + days[params.value[1]];
                    },
                },
                grid: {
                    left: 2,
                    bottom: 10,
                    right: 10,
                    top: 0,
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    data: hours,
                    boundaryGap: false,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#999',
                            type: 'dashed',
                        },
                    },
                    axisLine: {
                        show: false,
                    },
                },
                yAxis: {
                    type: 'category',
                    data: days,
                    axisLine: {
                        show: false,
                    },
                },
                series: [{
                    name: 'Punch Card',
                    type: 'scatter',
                    center: ['50%', '50%'],
                    symbolSize: function (val) {
                        return val[2] * 2;
                    },
                    data: data,
                    animationDelay: function (idx) {
                        return idx * 4;
                    },
                }],
            };
            myChart.setOption(option, true);
        },
        // 产品型号需求
        productDemandCharts() {
            const myChart = this.$echarts.init(document.getElementById('productModel'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985',
                        },
                    },
                },
                legend: {
                    itemWidth: 14,
                    itemHeight: 8,
                    padding: [10, 0, 0, 0],
                    data: ['C10', 'C20', 'C25', 'C30', 'C45'],
                },
                grid: {
                    // left: '2%',
                    top: '7%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                        type: 'category',
                        boundaryGap: false,
                        data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                    },
                ],
                yAxis: [
                    {
                        name: '(万方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        nameTextStyle: {
                            padding: [0, 0, -10, -30],
                            fontSize: 10,
                            color: '#666666',
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                        axisTick: { // y轴刻度线
                            show: false,
                        },
                        type: 'value',
                    },
                ],
                series: [
                    {
                        name: 'C10',
                        type: 'line',
                        stack: '总量',
                        data: [120, 132, 101, 134, 90, 230, 210],
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#AEEEFF',
                            }, {
                                offset: 1,
                                color: '#fff',
                            }]),
                        },
                    },
                    {
                        name: 'C20',
                        type: 'line',
                        stack: '总量',
                        data: [220, 182, 191, 234, 290, 330, 310],
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#D3AFE6',
                            }, {
                                offset: 1,
                                color: '#ffe',
                            }]),
                        },
                    },
                    {
                        name: 'C25',
                        type: 'line',
                        stack: '总量',
                        data: [150, 232, 201, 154, 190, 330, 410],
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#F9B089',
                            }, {
                                offset: 1,
                                color: '#ffe',
                            }]),
                        },
                    },
                    {
                        name: 'C30',
                        type: 'line',
                        stack: '总量',
                        data: [320, 332, 301, 334, 390, 330, 320],
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#F9B089',
                            }, {
                                offset: 1,
                                color: '#ffe',
                            }]),
                        },
                    },
                    {
                        name: 'C45',
                        type: 'line',
                        stack: '总量',
                        data: [820, 932, 901, 934, 1290, 1330, 1320],
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#73AA70',
                            }, {
                                offset: 1,
                                color: '#ffe',
                            }]),
                        },
                    },
                ],
            };
            myChart.setOption(option, true);
        },
        // 已供方量
        alreadySuppliedCharts() {
            const that = this;
            const myChart = this.$echarts.init(document.getElementById('alreadySupplied'));
            const option = {
                animation: true,
                series: [{
                    type: 'pie',
                    center: ['50%', '50%'],
                    radius: ['45%', '65%'],
                    color: ['#FEE449', '#00FFFF', '#00FFA8', '#9F17FF', '#FFE400', '#F76F01', '#01A4F7', '#FE2C8A'],
                    itemStyle: { // 白色间隔
                        normal: {
                            borderColor: '#ffffff',
                            borderWidth: 2,
                        },
                    },
                    label: {
                        normal: {
                            // formatter: '{b|{b}:}{per|{d}%} ',
                            formatter: '{b}:{d}%',
                            borderRadius: 4,
                            rich: {
                                c: {
                                    fontSize: 10,
                                    color: '#eee',
                                },
                                per: {
                                    // 默认百分比颜色
                                    color: that.color,
                                    fontSize: 10,
                                    padding: [5, 8],
                                    borderRadius: 2,
                                },
                            },
                            // 默认颜色
                            textStyle: {
                                // 整体选中颜色
                                color: that.color,
                                fontSize: 10,
                            },
                        },
                    },
                    emphasis: {
                        label: {
                            formatter: '{b|{b}:}{per|{d}%} ',
                            rich: {
                                b: {
                                    // 选中名称颜色
                                    color: that.color,
                                    fontSize: 15,
                                },
                                per: {
                                    // 选中百分比颜色
                                    color: that.color,
                                    fontSize: 15,
                                },
                            },
                        },
                    },
                    data: this.ydata,
                }],
            };
            myChart.setOption(option);
            setTimeout(() => {
                myChart.on('mouseover', function (params) {
                    if (params.name === that.ydata[0].name) {
                        myChart.dispatchAction({
                            type: 'highlight',
                            seriesIndex: 0,
                            dataIndex: 0,
                        });
                    } else {
                        myChart.dispatchAction({
                            type: 'downplay',
                            seriesIndex: 0,
                            dataIndex: 0,
                        });
                        that.text = params.name;
                        that.subText = params.percent;
                    }
                    that.selectColorOne = params.color;
                });
            }, 1000);
        },
        // 需求趋势
        demandTrendCharts() {
            const myChart = this.$echarts.init(document.getElementById('demandTrend'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                grid: {
                    top: '15%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                legend: {
                    textStyle: { // 图例文字的样式
                        fontSize: 10,
                    },
                    itemWidth: 14,
                    itemHeight: 8,
                    data: ['降水量', '平均温度'],
                },
                xAxis: [
                    {
                        splitLine: { show: false },
                        axisTick: { // y轴刻度线
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        type: 'category',
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                        axisPointer: {
                            type: 'shadow',
                        },
                    },
                ],
                yAxis: [
                    {
                        splitLine: { show: false },
                        axisTick: { // y轴刻度线
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        nameTextStyle: {
                            padding: [0, 0, -0, -20],
                            fontSize: 12,
                            color: '#666666',
                        },
                        type: 'value',
                        name: '(方）',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                            formatter: '{value}',
                        },
                    },
                    {
                        splitLine: { show: false },
                        axisTick: { // y轴刻度线
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        type: 'value',
                        name: '(元/方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                            formatter: '{value}',
                        },
                    },
                ],
                series: [
                    {
                        name: '降水量',
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: '#5196F3', // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#00E5D5', // 100% 处的颜色
                                }], false),
                            },
                        },
                        barWidth: 10,
                        data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3],
                    },
                    {
                        name: '平均温度',
                        type: 'line',
                        yAxisIndex: 1,
                        itemStyle: {
                            normal: {
                                color: '#FA904A',
                            },
                        },
                        data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2],
                    },
                ],
            };
            myChart.setOption(option, true);
        },
        // 合同回款率
        contractRefundCharts() {
            const myChart = this.$echarts.init(document.getElementById('contractRefund'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                legend: {
                    itemWidth: 8,
                    itemHeight: 8,
                    data: ['蒸发量', '降水量', '平均温度'],
                },
                grid: {
                    top: '15%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                        axisPointer: {
                            type: 'shadow',
                        },
                        splitLine: { show: false },
                        axisTick: { // y轴刻度线
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '元',
                        splitLine: { show: false },
                        axisTick: { // y轴刻度线
                            show: false,
                        },
                        nameTextStyle: {
                            padding: [0, 0, -0, -20],
                            fontSize: 12,
                            color: '#666666',
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                            formatter: '{value}',
                        },
                    },
                    {
                        type: 'value',
                        name: '温度',
                        splitLine: { show: false },
                        axisTick: { // y轴刻度线
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                            formatter: '{value}',
                        },
                    },
                ],
                series: [
                    {
                        name: '蒸发量',
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: '#5196F3', // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#00E5D5', // 100% 处的颜色
                                }], false),
                            },
                        },
                        data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3],
                    },
                    {
                        name: '降水量',
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: '#FF715A', // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#FABD37', // 100% 处的颜色
                                }], false),
                            },
                        },
                        data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3],
                    },
                    {
                        name: '平均温度',
                        type: 'line',
                        itemStyle: {
                            normal: {
                                color: '#C754E0FF',
                            },
                        },
                        yAxisIndex: 1,
                        data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2],
                    },
                ],
            };

            myChart.setOption(option, true);
        },
        // 施工进度
        constructionProgressCharts() {
            const myChart = this.$echarts.init(document.getElementById('constructionProgress'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
                    },
                },
                legend: {
                    padding: [0, 0, 0, 100],
                    itemWidth: 8,
                    itemHeight: 8,
                    data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎', '预计方量'],
                },
                grid: {
                    top: '15%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: {
                    splitLine: { show: false },
                    axisTick: { // y轴刻度线
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    type: 'category',
                    data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                },
                yAxis: [
                    {
                        splitLine: { show: false },
                        axisTick: { // y轴刻度线
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        nameTextStyle: {
                            padding: [0, 0, -0, -20],
                            fontSize: 12,
                            color: '#666666',
                        },
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                            formatter: '{value}',
                        },
                        type: 'value',
                        name: '(方)',
                    },
                ],
                series: [
                    {
                        name: '直接访问',
                        type: 'bar',
                        stack: '总量',
                        label: {
                            show: true,
                            position: 'right',
                            fontSize: 10,
                        },
                        data: [320, 302, 301, 334, 390, 330, 320],
                    },
                    {
                        name: '邮件营销',
                        type: 'bar',
                        stack: '总量',
                        label: {
                            show: true,
                            position: 'right',
                            fontSize: 10,
                        },
                        barWidth: 10,
                        data: [120, 132, 101, 134, 90, 230, 210],
                    },
                    {
                        name: '联盟广告',
                        type: 'bar',
                        stack: '总量',
                        label: {
                            show: true,
                            position: 'right',
                            fontSize: 10,
                        },
                        barWidth: 10,
                        data: [220, 182, 191, 234, 290, 330, 310],
                    },
                    {
                        name: '视频广告',
                        type: 'bar',
                        stack: '总量',
                        label: {
                            show: true,
                            position: 'right',
                            fontSize: 10,
                        },
                        data: [150, 212, 201, 154, 190, 330, 410],
                    },
                    {
                        name: '搜索引擎',
                        type: 'bar',
                        stack: '总量',
                        label: {
                            show: true,
                            position: 'right',
                            fontSize: 10,
                        },
                        data: [820, 832, 901, 934, 1290, 1330, 1320],
                    },
                    {
                        name: '预计方量',
                        type: 'line',
                        data: [820, 932, 901, 934, 1290, 1330, 1320],
                    },
                ],
            };
            myChart.setOption(option, true);
        },
        // 直接材料成本
        directMaterialCostCharts() {
            const that = this;
            const myChart = this.$echarts.init(document.getElementById('directMaterialCost'));
            const option = {
                animation: true,
                series: [{
                    type: 'pie',
                    center: ['50%', '50%'],
                    radius: ['45%', '65%'],
                    color: ['#FEE449', '#00FFFF', '#00FFA8', '#9F17FF', '#FFE400', '#F76F01', '#01A4F7', '#FE2C8A'],
                    itemStyle: { // 白色间隔
                        normal: {
                            borderColor: '#ffffff',
                            borderWidth: 2,
                        },
                    },
                    label: {
                        normal: {
                            formatter: '{b}:{d}%',
                            borderRadius: 4,
                            rich: {
                                c: {
                                    fontSize: 10,
                                    color: '#eee',
                                },
                                per: {
                                    // 默认百分比颜色
                                    // color: that.color,
                                    fontSize: 10,
                                    padding: [5, 8],
                                    borderRadius: 2,
                                },
                            },
                            // 默认颜色
                            textStyle: {
                                // 整体选中颜色
                                // color: that.color,
                                fontSize: 10,
                            },
                        },
                    },
                    emphasis: {
                        label: {
                            formatter: '{b|{b}:}{per|{d}%} ',
                            rich: {
                                b: {
                                    // 选中名称颜色
                                    // color: that.color,
                                    fontSize: 15,
                                },
                                per: {
                                    // 选中百分比颜色
                                    // color: that.color,
                                    fontSize: 15,
                                },
                            },
                        },
                    },
                    data: this.ydataTwo,
                }],
            };
            myChart.setOption(option);
            setTimeout(() => {
                myChart.on('mouseover', function (params) {
                    if (params.name === that.ydataTwo[0].name) {
                        myChart.dispatchAction({
                            type: 'highlight',
                            seriesIndex: 0,
                            dataIndex: 0,
                        });
                        that.textTwo = that.ydataTwo[0].name;
                        that.subTextTwo = that.ydataTwo[0].value;
                    } else {
                        myChart.dispatchAction({
                            type: 'downplay',
                            seriesIndex: 0,
                            dataIndex: 0,
                        });
                        that.textTwo = params.name;
                        that.subTextTwo = params.percent;
                    }
                    that.selectColorTwo = params.color;
                });
            }, 1000);
        },
        changeBoxPage() {
            this.changeBox = !this.changeBox;
            this.init();
        },
    },
};

</script>
<style lang='stylus'>
.amap-overlay-text-container
    color #fff
    font-size .12rem
    border none
    background #77ADFF
    position relative
    overflow visible
    &:after
        content: ''
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-left: 0.1rem solid transparent;
        border-right: 0.1rem solid transparent;
        border-top: 0.1rem solid #77ADFF;
        bottom: -0.09rem;
        left: 50%;
        transform: translateX(-50%);
.amap-icon
    width: .19rem !important;
    height: .33rem !important;
    img
        width .19rem !important
        height .33rem !important
.dv-water-pond-level text
    font-size .26rem
#alreadySupplied
    width:100%;
    height:100px
    margin-top .2rem
#demandTrend
    width 100%
    height 240px
#constructionProgress
    width 100%
    height 240px
#contractRefund
    width 100%
    height 240px
#lineOfCredit
    width 100%
    height 240px
.contract-alert-box
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background rgba(0, 0, 0, 0.4);
    display flex
    flex-direction columns;
    justify-content center
    align-items center
    -moz-user-select none
    -webkit-user-select none
    user-select none
    .contract-page-one
        display flex
.contract-alert-content
    width 17rem
    min-height 2rem
    background #CFD5DC
    position relative
    h1
        font-size .18rem
        padding-left .1rem
        border-left .06rem solid #1577D2
        font-weight 800
        margin .2rem 0 .2rem .3rem
    .checkout-div-page
        position absolute
        bottom .2rem
        left 50%
        transform: translateX(-50%);
        display flex
        flex-direction column
        img
            width 1.26rem
            height .3rem
.content-left
    flex 2
    margin .2rem
.content-left-top
    display flex
    background #FFFFFF
.top-box-one
    flex 1.2
    font-size .16rem
    border-right .03rem solid #D4D8DD
    color #333333
    div
        display flex
        margin-bottom .17rem
        p
            flex 1
            &:first-child
                margin-left .3rem
        .blue-color
            color #1354FB
            display flex
            align-items: center;
            span
                font-size: .2rem;
                margin-left: .03rem;
    .bottom-btn
        padding-bottom .3rem
        display flex
        button
            display flex
            align-items center
            justify-content: center;
            width 1.5rem
            height .3rem
            background #BAD0FE;
            color #1354FA
            border:1px solid rgba(186, 208, 254, 1);
            span
                font-size .2rem
            &:first-child
                margin-left .3rem
            &:last-child
                margin-left: 1.3rem;
.top-box-tow
    flex 1
    display flex
    flex-direction column
    .box-right-top
        flex 1
        border-bottom .03rem solid #D4D8DD
        display flex
        .box-right-top-mes
            flex 1
            .message-box-tow
                display flex
                margin-left .2rem
                div
                    width 1rem
                    height .8rem
                    background:rgba(32,150,242,0.04);
                    display flex
                    flex-direction column
                    justify-content center
                    align-items center
                    &:first-child
                        p:first-child
                            color #333333
                            font-size .16rem
                            span
                                color: #2096F2;
                                font-size: .2rem;
                        p:last-child
                            color #2096F2
                            font-size .18rem
                            margin-top .1rem
                    &:last-child
                        margin-left .1rem
                        background:rgba(250,132,54,0.04);
                        p:first-child
                            color #333333
                            font-size .16rem
                            span
                                color: #FA8436;
                                font-size: .2rem;
                        p:last-child
                            color #FA8436
                            font-size .28rem
                            margin-top .1rem
                            font-family QuartzLTStd
        .box-right-top-map
            width 2.4rem
            height 1.16rem
            border .03rem solid #D4D8DD
            margin-right .2rem
            margin-top .3rem
    .box-right-bottom
        flex 1
        .image-progress
            display flex
            justify-content space-between;
            align-items center
            .image-progress-look-more
                margin-right .2rem
                width .7rem
                height .24rem
                color #1354FB
                font-size .14rem
                background #BAD0FE
                display: flex;
                justify-content: center;
                align-items: center;
                span
                    font-size: 0.19rem;
                    color: #1354fb;
                    margin-top: .01rem;
        div
            display flex
            p
                flex 1
                width .9rem
                height .8rem
                margin-left .2rem
                background yellow
                &:last-child
                    margin-right .2rem
.content-left-bottom
    margin-top .14rem
    background #FFFFFF
    display flex
    flex-direction column
    .time-line-box
        display flex
        border-bottom .03rem solid #D4D8DD
        flex-direction column
        .time-line-title
            display flex
            align-items center;
            span
                width .6rem
                height .3rem
                border .01rem solid #D7D7D7
                cursor pointer
                font-size .18rem
                display flex
                justify-content center
                align-items center
                margin-left .25rem
        .time-axis
            padding 0 .2rem;
            display flex
            div
                flex 1
                margin-right .04rem
                display flex
                flex-direction column
                align-items center
                postion relative
                &:last-child
                    margin-right 0
                p
                    width 100%
                    background #D7D7D7
                    height .1rem
                    margin-top .05rem;
                b
                    font-size .2rem
                    color #5588F1
                span
                    color #979EA7
                    font-size .12rem
            .everyWeek span
                color #333333
                font-weight 800
        .time-axis-bottom-blcok
            display flex
            color #333333
            padding 0 .2rem
            font-size .14rem
            div
                flex 1
                display flex
                align-items center
                height .5rem
                b
                    color #FA8436
                    font-size .3rem
                    font-family QuartzLTStd
                    margin-top: .1rem;
            .orange-show-time
                b
                    color #1354FA
                span
                    color #1354FB
                    font-size .24rem
    .echarts-two
        display flex
        div
            flex 1
            &:first-child
                border-right .03rem solid #D4D8DD
        .take-time
            display flex
            flex-direction column
            align-items center
.content-right
    flex 1
    background #FFFFFF
    margin .2rem
    margin-left 0
    display flex
    flex-direction column
    .number-div
        flex 1
        display flex
        flex-direction column
        border-bottom .04rem solid #CED5DE
        .one-page-right-top
            padding .2rem
            position relative
            .position-div
                position absolute
                top 1.46rem
                left 50%
                transform: translateX(-50%);
                z-index 55
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                p:first-child
                    font-size: .2rem;
                p:last-child
                    font-size: .14rem;
        .number-title
            width 65%;
            height .43rem
            display flex
            align-items center
            position relative
            img
                width .5rem
                height .43rem
                position absolute
            p
                width 90%
                height .4rem
                display flex
                align-items center
                background:rgba(77,175,80,0.1);
                font-size .18rem
                padding-left .35rem
                color #333333
                position absolute
                left .35rem
                span
                    color #4DAF50
                    font-size .36rem
                    font-family QuartzLTStd
                    margin-top .1rem
        .number-content
            display flex
            justify-content space-between
            margin-top .2rem
            div
                width 1.5rem
                display flex
                flex-direction column
                justify-content center
                align-items center
                &:nth-child(1){
                    background:rgba(32,150,243,0.06);
                }
                &:nth-child(2){
                    background:rgba(250,132,54,0.06);
                }
                &:nth-child(3){
                    background:rgba(77,174,80,0.06);
                }
                h3
                    color #333333
                    font-size .14rem
                    padding-top .03rem;
                p
                    font-size .24rem
                    margin-top .05rem
                    padding-top .03rem;
                    font-family QuartzLTStd
                    margin-top .1rem
    .price-div
        flex 1
        border-bottom .04rem solid #CED5DE
        .price-title
            width 65%;
            height .43rem
            display flex
            align-items center
            position relative
            img
                width .5rem
                height .43rem
                position absolute
            p
                width 90%
                height .4rem
                display flex
                align-items center
                background:rgba(250,132,54,0.1);
                font-size .18rem
                padding-left .2rem
                color #333333
                left .35rem
                position absolute
                span
                    color #FA8436
                    font-size .36rem
                    font-family QuartzLTStd
                    margin-top .1rem
        .prize-content
            display flex
            flex-wrap wrap
            justify-content space-between
            align-items center
            div
                width 2rem
                display flex
                flex-direction column
                margin-top .35rem
                &:nth-child(odd)
                    margin-left .4rem
                // &:nth-child(even)
                //     margin-right .3rem
                button
                    width 1.7rem
                    height .3rem
                    border 1px solid #C7CFE3 !important
                    color #1354FB
                    background:rgba(85,136,241,0.1);
                    border none
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:last-child
                        margin-top .13rem
                    span
                        color #1354FB
                        font-size .22rem
                        margin-top: .02rem;
                span
                    color #FC636E
                    font-size .2rem
                p:first-child
                    color #333333
                    font-size .18rem
                p:last-child
                    margin-top .05rem
                    width 1.7rem
                    height .5rem
                    display flex
                    align-items center
                    justify-content center
                    font-family QuartzLTStd
            .prize-green-div
                span
                    color #4DAF50
                p:last-child
                    background:rgba(77,174,79,0.06);
                    color:#4DAF50
            .prize-blue-div
                span
                    color #2096F3
                p:last-child
                    background:rgba(32,150,243,0.06);
                    color:#2096F3
            .prize-button-div
                margin-top .55rem
    .receivables-div
        flex 1
        .receivables-title
            width 65%;
            height .43rem
            display flex
            align-items center
            position relative
            img
                width .5rem
                height .43rem
                position absolute
            p
                width 90%
                height .4rem
                display flex
                align-items center
                background:rgba(32,150,243,0.1);
                font-size .18rem
                padding-left .2rem
                color #333333
                position absolute
                left .35rem
                span
                    color #2096F3
                    font-size .36rem
                    font-family QuartzLTStd
                    margin-top .1rem
        .receivables-content
            display flex
            justify-content space-between
            margin-top .3rem
            div
                border 1px solid #D7D7D7
                background:rgba(32,150,243,0.04);
                flex 1
                height .6rem
                display flex
                flex-direction column
                justify-content center
                align-items center
                &:nth-child(2)
                    margin 0 .4rem
                p:first-child
                    color #333333
                    font-size .14rem
                    margin-top .05rem
                p:last-child
                    color #2096F3
                    font-size .28rem
                    font-family QuartzLTStd
                    margin-top .05rem
            .receivables-green-div
                background:rgba(85,136,241,0.04);
                border:1px solid rgba(215, 215, 215, 1);
                p:last-child
                    color #4DAF4E
        .receivables-center
            width 100%
            height .6rem
            border 1px solid #D7D7D7
            background:rgba(32,150,243,0.04);
            display flex
            flex-direction column
            justify-content center
            align-items center
            margin-top .3rem
            p:first-child
                color #333333
                font-size .14rem
                margin-top .05rem
            p:last-child
                color #2096F3
                font-size .28rem
                font-family QuartzLTStd
                margin-top .05rem
        .detailed-button
            width 100%
            text-align center
            margin-top .25rem
            button
                width: 1.3rem;
                height: 0.3rem;
                border: 1px solid #c7cfe3 !important;
                color: #1354fb;
                background: rgba(85,136,241,0.1);
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left 50%;
                transform: translateX(-50%);
                span
                    color: #1354fb;
                    font-size: 0.22rem;
                    margin-top: 0.02rem;
.contract-page-two
    display flex
    .contract-left
        flex 2
        background #FFFFFF
        margin .2rem
        display flex
        flex-wrap wrap
        .chart-one
            width 50%
            border-bottom .02rem solid #CED1DE
        .chart-two
            width 50%
            border-left .02rem solid #CED1DE
        .chart-three
            width 50%
            border-right .02rem solid #CED1DE
        .chart-four
            width 50%
            border-top .02rem solid #CED1DE
            .chart-four-content
                display flex
                .water-level
                    display flex
                    flex-direction column
                    justify-content center
                    align-items center
                    flex 1
                    .water-level-block
                        width 1.7rem
                        display flex
                        flex-direction column
                        justify-content center
                        align-items center
                        margin-top: .3rem;
                        h3
                            color #333333
                            font-size .16rem
                            padding: .1rem 0;
                        p
                            font-size .24rem
                            font-family QuartzLTStd
                    .water-block-blue
                        background:rgba(85,136,241,0.04);
                        border:1px solid rgba(215, 215, 215, 1);
                        p
                            color #5588F1
                    .water-block-orange
                        background:rgba(250,144,74,0.04);
                        border:1px solid rgba(215, 215, 215, 1);
                        p
                            color #FA904A
                .blue-water-level
                    border-right: 1px dashed #979EA7;
    .contract-right
        flex 1
        background #FFFFFF
        margin .2rem
        margin-left 0
        display flex
        flex-direction column
        .contract-right-top
            flex 1
            border-bottom .1rem solid #D0D6DD
            padding .2rem
            position relative
            .position-div
                position absolute
                top: 1.96rem;
                left 50%
                transform: translateX(-50%);
                z-index 55
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                p:first-child
                    font-size: .2rem;
                p:last-child
                    font-size: .14rem;
            .number-title
                width 65%;
                height .43rem
                display flex
                align-items center
                position relative
                img
                    width .5rem
                    height .43rem
                    position absolute
                p
                    width 90%
                    height .4rem
                    display flex
                    align-items center
                    background:rgba(31,190,204,0.1);
                    font-size .18rem
                    padding-left .2rem
                    color #333333
                    position absolute
                    left .35rem
                    span
                        color #1FBECC
                        font-size .36rem
                        font-family QuartzLTStd
                        margin-top .1rem
            .contract-right-top-center
                display flex
                justify-content space-between
                margin-top .17rem
                align-items: center;
                margin-bottom .2rem;
                p
                    color #979EA7
                    font-size .14rem
                    margin-left .28rem
                    span
                        font-size .28rem
                        color #12C27C
                        font-family QuartzLTStd
                button
                    background:rgba(85,136,241,0.1);
                    border:1px solid rgba(186, 208, 254, 1);
                    color #1354FB
                    width 1.3rem
                    height .3rem
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    span
                        font-size: .2rem;
                        margin-top: .005rem;
        .contract-right-center
            flex 1
            border-bottom .1rem solid #D0D6DD
            padding .2rem
            display flex
            flex-direction: column;
            .number-title
                width 65%;
                height .43rem
                display flex
                align-items center
                position relative
                img
                    width .5rem
                    height .43rem
                    position absolute
                p
                    width 90%
                    height .4rem
                    display flex
                    align-items center
                    background:rgba(250,132,54,0.1);
                    font-size .18rem
                    padding-left .2rem
                    color #333333
                    position absolute
                    left .35rem
                    span
                        color #FA8436
                        font-size .36rem
                        font-family QuartzLTStd
                        margin-top .1rem
            .contract-center-bottom
                display flex
                flex-wrap wrap
                div
                    width: 49.8%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-top: .4rem;
                    padding-left: 0.6rem;
                    p
                        font-size .16rem
                        color #333333
                        b
                            width: 0.1rem;
                            height: 0.1rem;
                            border-radius: 0.2rem;
                            display: inline-block;
                            background: #FC636E;
                    span
                        display: block;
                        font-size: .3rem;
                        font-family: QuartzLTStd;
                        color: #FC636E;
                        margin-left: 0.15rem;
                        margin-top .08rem
                .shuxian
                    width 1px
                    height .2rem
                    background #D7D7D7
                    display: inline-block;
                    margin-top: .7rem;
                .bottom-width-bfb
                    width 100%
                .blue-color-contract
                    p b
                        background #2096F3
                    span
                        color #2096F3
                        margin-top .15rem
        .contract-right-bottom
            flex 1
            padding .2rem
            ul
                display flex
                flex-direction: column;
                li
                    display flex
                    height .4rem
                    justify-content space-between
                    margin-bottom: .15rem;
                    align-items center
                    p
                        color #333333
                        font-size .18rem
                        display flex
                        align-items center
                        span
                            color #FC636E
                            font-size .2rem
                            display: inline-block;
                            margin-right: 0.1rem;
                    span
                        color #979EA7
                        font-size .14rem
                    button
                        width .6rem
                        height .24rem
                        background:rgba(85,136,241,0.1);
                        border:1px solid rgba(186, 208, 254, 1);
                        color #1354FB
                        font-size .14rem
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        span
                            font-size: .16rem;
                            color: #1354fb;
                            margin-top: .04rem;
</style>